export const env = {
  baseUrl:
    window.location.hostname === "localhost"
      ? "http://40.112.165.64:8091/"
      : window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "") +
        "/",
  errorMsg: "Error occured while processing your request!",
  pageSize: 10,
  userInfo: "",
  serverDate: null,
  dateFormat: "",
  taxName: "",
  baseCountry: "",
  baseCurrency: "",
  paymentTerm: "",
  orgId: "",
  suppId: "",
  REACT_APP_SITE_KEY: "6LcCi7ooAAAAABvbR2gMYHzO0ZeBJbu9jaCCiv7f",
};

export const env_url = {
  login: "api/auth/login",
  logOut: "api/auth/logout",
  validateCaptcha: "api/captmgmt/validate-captcha",
  validatetoken: "api/auth/refresh",
  loadUser: "api/applmgmt/loadUser",
  getRoleAuthorize: "api/applmgmt/getRoleAuthorize",
  supplierRegistration: "api/auth/supplierRegistration",
  getALLCountries: "api/auth/getALLCountries",
  checkUserNameSR: "api/auth/checkUserName",
  checkEmailIdSR: "api/auth/checkEmailId",
  checkMobileNoSR: "api/auth/checkMobileNo",
  checkSupplierNameSR: "api/auth/checkSupplierName",
  forgotPassword: "api/auth/forgotPassword",
  changePasswordSR: "api/auth/changePassword",
  getAllUsers: "api/usrmgmt/getALLUsersList",
  getAllProfileUsers: "api/usrmgmt/getAllProfileUsers",
  getAllRoles: "api/usrmgmt/getALLRolesList",
  getAllApprovers: "api/usrmgmt/getAllApprovers",
  getAllOrgUsers: "api/applmgmt/getAllOrgUsers",
  getAllWPOrgUsers: "api/usrmgmt/getAllWPOrgUsers",
  getAllSupplierUsers: "api/usrmgmt/getAllSupplierUsers",
  getAllOrgRoles: "api/usrmgmt/getAllOrgRoles",
  getAllAppFunctions: "api/usrmgmt/getAllAppFunctions",
  getAllAppFunctionsForSuppliers: "api/usrmgmt/getAllAppFunctionsForSuppliers",
  createSRMSRole: "api/usrmgmt/createSRMSRole",
  editSRMSRole: "api/usrmgmt/editSRMSRole",
  deleteSRMSRole: "api/usrmgmt/deleteSRMSRole",
  getSRMSUser: "api/usrmgmt/getSRMSUser",
  createSRMSNewUsers: "api/usrmgmt/processSRMSNewUsers",
  editSRMSUserDetails: "api/usrmgmt/editSRMSUserDetails",
  uploadProfilePhoto: "api/applmgmt/uploadProfilePhoto",
  getLoggedInUser: "api/usrmgmt/getLoggedInUser",
  checkUserName: "api/usrmgmt/checkUserName",
  checkEmailId: "api/usrmgmt/checkEmailId",
  checkMobileNo: "api/usrmgmt/checkMobileNo",
  resetPassword: "api/usrmgmt/resetUserPassword",
  changePassword: "api/applmgmt/changePassword",
  enableDisableUser: "api/usrmgmt/enableDisableUser",
  getSupplierByUserId: "api/usrmgmt/getSupplierByUserId",
  /** Starts Delegation Roles  */
  getAllRoleDelegations: "api/usrmgmt/getAllRoleDelegations",
  addRoleDelegation: "api/usrmgmt/addRoleDelegation",
  cancelRoleDelegations: "api/usrmgmt/cancelRoleDelegations",
  /** Ends Delegation Roles  */
  getAllEmployees: "api/usrmgmt/getAllEmployees",
  getAllUsersByRole: "api/usrmgmt/getAllUsersByRole",

  /** Administration */
  getALLBusinessEntities: "api/admmgmt/getALLBusinessEntities",
  getALLMasterDataEntities: "api/admmgmt/getALLMasterDataEntities",
  getALLExtSysTrxnDtls: "api/admmgmt/getALLExtSysTrxnDtls",
  getALLBusinessSegMst: "api/admmgmt/getALLBusinessSegMst",
  getALLBusinessSegDtls: "api/admmgmt/getALLBusinessSegDtls",
  addBusSegMst: "api/admmgmt/addBusSegMst",
  addUpdateAllBusSegDtl: "api/admmgmt/addUpdateAllBusSegDtl",
  addBusSegDtl: "api/admmgmt/addBusSegDtl",
  deleteBusSegDtl: "api/admmgmt/deleteBusSegDtl",
  actDeActBusSegDtl: "api/admmgmt/actDeActBusSegDtl",
  getALLSegment2BySeg1: "api/admmgmt/getALLSegment2BySeg1",
  getALLCurrencies: "api/admmgmt/getALLCurrencies",
  getCountryList: "api/admmgmt/getCountryList",

  /** Basic Settings */
  getALLLocations: "api/admmgmt/getALLLocations",
  getALLActiveLocations: "api/admmgmt/getALLActiveLocations",
  addLocation: "api/admmgmt/addLocation",
  editLocation: "api/admmgmt/editLocation",
  deleteLocation: "api/admmgmt/deleteLocation",
  actDeActLocation: "api/admmgmt/actDeActLocation",
  getALLPaymentTerms: "api/admmgmt/getALLPaymentTerms",
  getALLActivePaymentTerms: "api/admmgmt/getALLActivePaymentTerms",
  addPaymentTerms: "api/admmgmt/addPaymentTerms",
  editPaymentTerms: "api/admmgmt/editPaymentTerms",
  deletePaymentTerms: "api/admmgmt/deletePaymentTerms",
  actDeActPaymentTerms: "api/admmgmt/actDeActPaymentTerms",
  getALLTaxCodes: "api/admmgmt/getALLTaxCodes",
  getALLActiveTaxCodes: "api/admmgmt/getALLActiveTaxCodes",
  addTaxCode: "api/admmgmt/addTaxCode",
  editTaxCode: "api/admmgmt/editTaxCode",
  deleteTaxCode: "api/admmgmt/deleteTaxCode",
  actDeActTaxes: "api/admmgmt/actDeActTaxes",
  getOrganizationInfo: "api/admmgmt/getOrganizationInfo",
  updateOrgInfo: "api/admmgmt/updateOrgInfo",
  changeOrgLogo: "api/admmgmt/changeOrgLogo",
  updateOrgPreferences: "api/admmgmt/updateOrgPreferences",
  getAllSubsidiaries: "api/admmgmt/getAllSubsidiaries",
  processSubsidiary: "api/admmgmt/processSubsidiary",
  getSubsidiaryById: "api/admmgmt/getSubsidiaryById",
  getALLAppProperties: "api/admmgmt/getALLAppProperties",
  setAppProperty: "api/admmgmt/setAppProperty",
  getAppProperty: "api/admmgmt/getAppProperty",
  getLookupsByKey: "api/admmgmt/getLookupsByKey",
  createNewTnC: "api/admmgmt/createNewTnC",
  getAllTnCs: "api/admmgmt/getAllTnCs",
  getAllTnCByModuleName: "api/admmgmt/getAllTnCByModuleName",
  getTnCById: "api/admmgmt/getTnCById",
  getTermsConditionDocument: "api/admmgmt/getTermsAndConditionsDoc",
  uploadTermsConditionDocument: "api/admmgmt/termsConditionUpload",
  deleteTermsConditionDocument: "api/admmgmt/delete/termsConditionDoc",

  /** Manage Lookups */
  getALLLookups: "api/admmgmt/getALLLookUpValues",
  addLookup: "api/admmgmt/addLookup",
  editLookup: "api/admmgmt/editLookup",
  deleteLookup: "api/admmgmt/deleteLookup",
  actDeActLookup: "api/admmgmt/actDeActLookup",

  /** Setup Notification */
  getALLNotifs: "api/admmgmt/getALLNotifs",
  getALLNotifsTypes: "api/admmgmt/getALLNotifsTypes",
  addNotification: "api/admmgmt/addNotification",
  deleteNotification: "api/admmgmt/deleteNotification",
  editNotification: "api/admmgmt/editNotification",
  getRoleUsers: "api/admmgmt/getRoleUsers",

  /** Approval Workflow */
  getALLWFDefinitions: "api/admmgmt/getALLWFDefinitions",
  addWFStep: "api/admmgmt/addWFStep",
  addWFAssignee: "api/admmgmt/addWFAssignee",
  deleteWFStep: "api/admmgmt/deleteWFStep",
  deleteWFAssignee: "api/admmgmt/deleteWFAssignee",
  editWFStepTitle: "api/admmgmt/editWFStepTitle",
  getWFDefinitionById: "api/admmgmt/getWFDefinitionById",

  /** Amount based Approvers */
  getALLAmtBasedApprovers: "api/admmgmt/getALLAmtBasedApprovers",
  processAmtBasedApprovers: "api/admmgmt/processAmtBasedApprovers",
  deleteAmtBasedApprovers: "api/admmgmt/deleteAmtBasedApprovers",

  /** Budgets */
  getALLPeriods: "api/budgmgmt/getALLPeriods",
  getPeriodById: "api/budgmgmt/getPeriodById",
  getBudgets: "api/budgmgmt/getALLBudgets",
  getALLBudgets: "api/budgmgmt/getALLApprovedBudgets",
  getALLBudgetOwners: "api/budgmgmt/getALLBudgetOwners",
  getBudgetById: "api/budgmgmt/getBudgetById",
  addBudget: "api/budgmgmt/addBudget",
  addBudgetLine: "api/budgmgmt/addBudgetLine",
  addBudgetPeriodAmtMap: "api/budgmgmt/addBudgetPeriodAmtMap",
  addBudgetDepartMap: "api/budgmgmt/addBudgetDepartMap",
  addBudgetLocMap: "api/budgmgmt/addBudgetLocMap",
  deleteBudget: "api/budgmgmt/deleteBudget",
  deleteBudgetLine: "api/budgmgmt/deleteBudgetLine",
  calculateBudgetTotal: "api/budgmgmt/calculateBudgetTotal",
  processBudgetNotes: "api/budgmgmt/processBudgetNotes",
  submitBudgetApproval: "api/budgmgmt/submitBudgetApproval",
  processBudgetApprovalStep: "api/budgmgmt/processBudgetApprovalStep",
  reviseBudget: "api/budgmgmt/reviseBudget",
  getBudgetRevisionHst: "api/budgmgmt/getBudgetRevisionHst",
  copyBudget: "api/budgmgmt/copyBudget",

  /** Feedbacks */
  submitFeedback: "api/admmgmt/submitFeedback",
  getALLFeedbacks: "api/admmgmt/getALLFeedbacks",
  getFeedbackById: "api/admmgmt/getFeedbackById",
  getFeedbackAnalysis: "api/admmgmt/getFeedbackAnalysis",

  /** Suppliers */
  getALLSuppliers: "api/suppmgmt/getALLSuppliers",
  getALLActiveSuppliers: "api/suppmgmt/getALLActiveSuppliers",
  getSupplierObject: "api/suppmgmt/getSupplierbyId",
  getSupplierDetails: "api/suppmgmt/getSupplierDetailsById",
  getSupplierUserAccount: "api/suppmgmt/getSupplierUserAccount",
  getAllInvitations: "api/suppmgmt/getAllInvitations",
  getALLMigratedSuppliers: "api/suppmgmt/getALLMigratedSuppliers",
  editMigratedSupplier: "api/suppmgmt/updateSupplierEmailId",
  inviteSupplier: "api/suppmgmt/inviteSupplier",
  resendInvitation: "api/suppmgmt/resendInvitation",
  cancelInvitation: "api/suppmgmt/cancelInvitation",
  getALLActiveSuppliersByCategory:
    "api/suppmgmt/getALLActiveSuppliersByCategory",
  getALLActiveSuppliersByCategories:
    "api/suppmgmt/getALLActiveSuppliersByCategories",

  /** Sourcing */
  getALLPRs: "api/esourcemgmt/getALLPRs",
  getPRObject: "api/esourcemgmt/getPRbyPrNumber",
  createPR: "api/esourcemgmt/createPR",
  updatePR: "api/esourcemgmt/updatePR",
  deletePR: "api/esourcemgmt/deletePR",
  processPRNotes: "api/esourcemgmt/processPRNotes",
  addPRLine: "api/esourcemgmt/addPRLine",
  editPRLine: "api/esourcemgmt/editPRLine",
  deletePRLine: "api/esourcemgmt/deletePRLine",
  submitPRApproval: "api/esourcemgmt/submitPRApproval",
  processPRApprovalStep: "api/esourcemgmt/processPRApprovalStep",
  createPOFromPR: "api/esourcemgmt/createPOFromPR",
  copyPR: "api/esourcemgmt/copyPR",
  cancelPR: "api/esourcemgmt/cancelPR",
  processPRLinesImportXls: "api/esourcemgmt/processPRLinesImportXls",

  getALLPOs: "api/esourcemgmt/getALLPOs",
  getPODetailsByPoNo: "api/esourcemgmt/getPODetailsByPoNo",
  createPurchaseOrder: "api/esourcemgmt/createPurchaseOrder",
  updatePO: "api/esourcemgmt/updatePO",
  deletePO: "api/esourcemgmt/deletePO",
  processPONotes: "api/esourcemgmt/processPONotes",
  copyPO: "api/esourcemgmt/copyPO",
  cancelPO: "api/esourcemgmt/cancelPO",
  addPOLine: "api/esourcemgmt/addPOLine",
  editPOLine: "api/esourcemgmt/editPOLine",
  deletePOLine: "api/esourcemgmt/deletePOLine",
  submitPOApproval: "api/esourcemgmt/submitPOApproval",
  processPOApprovalStep: "api/esourcemgmt/processPOApprovalStep",
  createDeliveryNote: "api/esourcemgmt/createDeliveryNote",
  createReceipt: "api/esourcemgmt/createReceipt",
  downloadPO: "api/esourcemgmt/downloadPO",
  checkStockItems: "api/esourcemgmt/checkStockItems",
  processPOLinesImportXls: "api/esourcemgmt/processPOLinesImportXls",

  /** Invoice Management */
  getALLInvoices: "api/invoicemgmt/getALLInvoices",
  getInvoiceDetailsById: "api/invoicemgmt/getInvoiceDetailsById",
  raisePOInvoice: "api/invoicemgmt/raisePOInvoice",
  processInvoiceNotes: "api/invoicemgmt/processInvoiceNotes",
  processInvoiceApprovalStep: "api/invoicemgmt/processInvoiceApprovalStep",
  raiseNONPOInvoice: "api/invoicemgmt/raiseNONPOInvoice",
  getPDFPreview: "api/invoicemgmt/getPDFPreview",
  processInvoicePayment: "api/invoicemgmt/processInvoicePayment",
  downloadInvoiceDocument: "api/invoicemgmt/downloadInvoiceDocument",
  checkDuplicate: "api/invoicemgmt/checkDuplicate",
  processNONPOImportXls: "api/invoicemgmt/processNONPOImportXls",
  raisePOAdvanceInvoice: "api/invoicemgmt/raisePOAdvanceInvoice",

  /** Collaboration Management */

  postComments: "api/collaborationmgmt/postComments",
  getALLPostedComments: "api/collaborationmgmt/getALLPostedComments",
  getALLPostedCommentsBySupplierId:
    "api/collaborationmgmt/getALLPostedCommentsBySupplierId",
  postDocuments: "api/collaborationmgmt/postDocuments",
  getALLPostedDocuments: "api/collaborationmgmt/getALLPostedDocuments",
  deletePostedDocument: "api/collaborationmgmt/deletePostedDocument",
  downloadPostedDocument: "api/collaborationmgmt/downloadPostedDocument",

  /** Dashboard Management */
  getMyDashboardWidgets: "api/dashboardmgmt/getMyDashboardWidgets",
  getALLPendingTasks: "api/dashboardmgmt/getALLApprovalTasks",

  /** Emails */
  getALLEmails: "api/applmgmt/getALLEmails",
  updateEmailStatus: "api/admmgmt/updateEmailStatus",
  getALLUnReadEmailsCount: "api/admmgmt/getALLUnReadEmailsCount",

  /** Product Catalogue */
  getALLProdCategories: "api/productsmgmt/getALLProdCategories",
  getALLProdCategoriesWP: "api/productsmgmt/getALLProdCategoriesWP",
  createProdCategory: "api/productsmgmt/createProdCategory",
  updateProdCategory: "api/productsmgmt/updateProdCategory",
  deleteProdCategory: "api/productsmgmt/deleteProdCategory",
  actDeActProdCategory: "api/productsmgmt/actDeActProdCategory",
  getALLProdItems: "api/productsmgmt/getALLProdItems",
  addCatalogueItem: "api/productsmgmt/addCatalogueItem",
  updateCatalogueItem: "api/productsmgmt/updateCatalogueItem",
  deleteCatalogueItem: "api/productsmgmt/deleteCatalogueItem",
  deleteCatalogueImage: "api/productsmgmt/deleteCatalogueImage",
  processProductsImportXls: "api/productsmgmt/processProductsImportXls",
  getProdCategoriesByParentCategoryId:
    "api/productsmgmt/getProdCategoriesByParentCategoryId",
  getALLProdSubCategories: "api/productsmgmt/getALLProdSubCategories",

  /** Supplier Registration */
  supplierLanding: "api/registrationmgmt/supplierLanding",
  simpleSupplierRegistration: "api/suppmgmt/simpleSupplierRegistration",
  suppRegCompanyDetails: "api/registrationmgmt/registrationCompanyDetails",
  getCompanyDetails: "api/registrationmgmt/getCompanyDetails",
  getContactDetails: "api/registrationmgmt/getContactDetails",
  addContactDetail: "api/registrationmgmt/addContactDetail",
  editContactDetail: "api/registrationmgmt/editContactDetail",
  deleteContactDetail: "api/registrationmgmt/deleteContactDetail",
  getReferenceDetails: "api/registrationmgmt/getReferenceDetails",
  addReferenceDetail: "api/registrationmgmt/addReferenceDetail",
  editReferenceDetail: "api/registrationmgmt/editReferenceDetail",
  deleteReferenceDetail: "api/registrationmgmt/deleteReferenceDetail",
  getScopeOfSupplies: "api/registrationmgmt/getScopeOfSupplies",
  addScopeOfSupply: "api/registrationmgmt/addScopeOfSupply",
  deleteScopeOfSupplyById: "api/registrationmgmt/deleteScopeOfSupply",
  getAllBankMst: "api/registrationmgmt/getAllBankMst",
  getAllBankMstByCtry: "api/registrationmgmt/getAllBankMstByCtry",
  getAllBankBranchMstByBankId: "api/registrationmgmt/getAllBankBranchMst",
  getBankDetails: "api/registrationmgmt/getBankDetails",
  addBankingDetails: "api/registrationmgmt/addBankingDetails",
  editBankingDetails: "api/registrationmgmt/editBankingDetails",
  deleteBankingDetails: "api/registrationmgmt/deleteBankingDetails",
  deleteIBankingDetails: "api/registrationmgmt/deleteIBankingDetails",
  getRegistrationDocuments: "api/registrationmgmt/getRegistrationDocuments",
  uploadRegistrationDocuments:
    "api/registrationmgmt/uploadRegistrationDocuments",
  submitRegistrationApproval: "api/registrationmgmt/submitRegistrationApproval",
  processRegistrationApprovalStep:
    "api/registrationmgmt/processRegistrationApprovalStep",
  downloadRegistrationDocuments:
    "api/registrationmgmt/downloadRegistrationDocuments",

  /** Spend Analytics */
  getSpendAnalyticsWidgets: "api/reportmgmt/getSpendAnalyticsWidgets",
  getSpendBySuppliers: "api/reportmgmt/getSpendBySuppliers",
  getSpendByDepartments: "api/reportmgmt/getSpendByDepartments",
  getSpendTrend: "api/reportmgmt/getSpendTrend",
  getSpendByCategories: "api/reportmgmt/getSpendByCategories",
  getBudgetVsSpend: "api/reportmgmt/getBudgetVsSpend",
  getDetailSpendByCategory: "api/reportmgmt/getDetailSpendByCategory",
  getDetailSpendByDepartment: "api/reportmgmt/getDetailSpendByDepartment",
  getDetailSpendTrend: "api/reportmgmt/getDetailSpendTrend",
  getDetailSpendByBudget: "api/reportmgmt/getDetailSpendByBudget",
  getDetailSpendBySupplier: "api/reportmgmt/getDetailSpendBySupplier",
  getAvgApprovalsTime: "api/reportmgmt/getAvgApprovalsTime",
  getPurchaseCycleTime: "api/reportmgmt/getPurchaseCycleTime",
  getTopApprovers: "api/reportmgmt/getTopApprovers",

  /** Inventory */
  getALLWarehouses: "api/inventorymgmt/getALLWarehouses",
  addWarehouse: "api/inventorymgmt/addWarehouse",
  updateWarehouse: "api/inventorymgmt/updateWarehouse",
  deleteWarehouse: "api/inventorymgmt/deleteWarehouse",
  getWarehouseById: "api/inventorymgmt/getWarehouseById",
  updateWarehouseStatus: "api/inventorymgmt/updateWarehouseStatus",
  getAllInventoryItems: "api/inventorymgmt/getAllInventoryItems",
  processWMSItem: "api/inventorymgmt/processWMSItem",
  getWarehouseItemById: "api/inventorymgmt/getWarehouseItemById",
  deleteWmsItem: "api/inventorymgmt/deleteWmsItem",
  getAllWarehouseItems: "api/inventorymgmt/getAllWarehouseItems",

  /** Subscription */
  getMySubscriptionInfo: "api/subscriptionmgmt/getMySubscriptionInfo",
  getAllAvailablePlans: "api/subscriptionmgmt/getAllAvailablePlans",
  getSubscriptionPlanById: "api/subscriptionmgmt/getSubscriptionPlanById",
  getPlanByNoOfUsers: "api/subscriptionmgmt/getPlanByNoOfUsers",
  processSubscription: "api/subscriptionmgmt/processSubscription",
  getAllSubscriptionInvoices: "api/subscriptionmgmt/getAllSubscriptionInvoices",
  runtimeSubscriptionInvoicePdf:
    "api/subscriptionmgmt/runtimeSubscriptionInvoicePdf",
  subscriptionInvoicePdf: "api/subscriptionmgmt/subscriptionInvoicePdf",

  /** Site API -- Free Trail/Book a Demo */
  registerTrail: "api/saasmgmt/registerTrail",
  verifyTenant: "api/saasmgmt/verifyTenant",
  requestDemo: "api/saasmgmt/requestDemo",
  contactUs: "api/saasmgmt/contactus",
  contactUsNew: "api/saasmgmt/contactsUs",
  makeRightChoice: "api/saasmgmt/makeRightChoice",
  careers: "api/saasmgmt/career",

  /** Reports API */
  getSuppliersSummaryReport: "api/reportmgmt/getSuppliersSummaryReport",
  getPRSummaryReport: "api/reportmgmt/getPRSummaryReport",
  getPOSummaryReport: "api/reportmgmt/getPOSummaryReport",
  getInvoiceSummaryReport: "api/reportmgmt/getInvoiceSummaryReport",
  getApplicationUsersReport: "api/reportmgmt/getApplicationUsersReport",
  getBudgetsSummaryReport: "api/reportmgmt/getBudgetsSummaryReport",
  getCatalogueSummaryReport: "api/reportmgmt/getCatalogueSummaryReport",
  getInventorySummaryReport: "api/reportmgmt/getInventorySummaryReport",
  getInvoicesStatusAnalytics: "api/reportmgmt/getInvoicesStatusAnalytics",

  /** Bids API */
  getALLPublshBids: "api/bidmgmt/getALLPublshBids",
  getALLDraftBids: "api/bidmgmt/getALLDraftBids",
  getBidDetailsById: "api/bidmgmt/getBidDetailsById",
  createNewBid: "api/bidmgmt/createNewBid",
  deleteBid: "api/bidmgmt/deleteBid",
  processBidLine: "api/bidmgmt/processBidLine",
  deleteBidLine: "api/bidmgmt/deleteBidLine",
  uploadBidAttachments: "api/bidmgmt/uploadBidAttachments",
  updateBidAttachments: "api/bidmgmt/updateBidAttachments",
  downloadBidAttachment: "api/bidmgmt/downloadBidAttachment",
  deleteBidAttachments: "api/bidmgmt/deleteBidAttachments",
  addBidSuppliers: "api/bidmgmt/addBidSuppliers",
  delBidSuppliers: "api/bidmgmt/delBidSuppliers",
  getAllActiveSuppliers: "api/bidmgmt/getAllActiveSuppliers",
  getAllBidApprovers: "api/bidmgmt/getAllApprovers",
  saveBidTeam: "api/bidmgmt/saveBidTeam",
  deleteBidTeam: "api/bidmgmt/deleteBidTeam",
  saveBidReq: "api/bidmgmt/saveBidReq",
  getReqCategories: "api/bidmgmt/getReqCategories",
  deleteBidReq: "api/bidmgmt/deleteBidReq",
  saveBidClause: "api/bidmgmt/saveBidClause",
  deletebidClause: "api/bidmgmt/deletebidClause",
  publishBid: "api/bidmgmt/publishBid",
  getAllOpenBids: "api/bidmgmt/getAllOpenBids",
  getAllMyBidResponses: "api/bidmgmt/getAllMyBidResponses",
  acknowledgeBid: "api/bidmgmt/acknowledgeBid",
  getBidResponse: "api/bidmgmt/getBidResponse",
  updateBidReqResponse: "api/bidmgmt/updateBidReqResponse",
  updateBidReqRemarks: "api/bidmgmt/updateBidReqRemarks",
  updateBidPrice: "api/bidmgmt/updateBidPrice",
  updatePromisedDate: "api/bidmgmt/updatePromisedDate",
  uploadBidRespAttachments: "api/bidmgmt/uploadBidRespAttachments",
  updateTotal: "api/bidmgmt/updateTotal",
  saveAsDraftBidResponse: "api/bidmgmt/submitBidResponse",
  submitBidResponse: "api/bidmgmt/submitBidResponse",
  processBidLineOther: "api/bidmgmt/processBidLineOther",
  delBidLineOthers: "api/bidmgmt/delBidLineOthers",
  suppViewBidResponse: "api/bidmgmt/suppViewBidResponse",
  createBidFromPR: "api/bidmgmt/createBidFromPR",
  getAllBidTemplates: "api/bidmgmt/getAllBidTemplates",
  saveBidAsTemplate: "api/bidmgmt/saveBidAsTemplate",
  processBidSupplierNotes: "api/bidmgmt/processBidSupplierNotes",
  inviteNewSuppliers: "api/bidmgmt/inviteNewSuppliers",
  cancelBid: "api/bidmgmt/cancelBid",
  holdBid: "api/bidmgmt/holdBid",
  reOpenBid: "api/bidmgmt/reOpenBid",
  closeBid: "api/bidmgmt/closeBid",
  processBidApprovalStep: "api/bidmgmt/processBidApprovalStep",
  uploadBondDocument: "api/bidmgmt/uploadBondDocument",
  openBid: "api/bidmgmt/openBid",
  getBidEvaluateDtlsById: "api/bidmgmt/getBidEvaluateDtlsById",
  getBidResponseDtlsForScoring: "api/bidmgmt/getBidResponseDtlsForScoring",
  updateBidReqScore: "api/bidmgmt/updateBidReqScore",
  updateBidReqComments: "api/bidmgmt/updateBidReqComments",
  getRespTotalFinScoreUser: "api/bidmgmt/getRespTotalFinScoreUser",
  getRespTotalTechScoreUser: "api/bidmgmt/getRespTotalTechScoreUser",
  getRespTotalTechScore: "api/bidmgmt/getRespTotalTechScore",
  getRespTotalFinScore: "api/bidmgmt/getRespTotalFinScore",
  submitTechScore: "api/bidmgmt/submitTechScore",
  submitFinScore: "api/bidmgmt/submitFinScore",
  approveTechScore: "api/bidmgmt/approveTechScore",
  approveFinScore: "api/bidmgmt/approveFinScore",
  compareResponses: "api/bidmgmt/compareResponses",
  storeEvaluation: "api/bidmgmt/storeEvaluation",
  awardBid: "api/bidmgmt/awardBid",
  getAwardDetails: "api/bidmgmt/getAwardDetails",
  saveAwardAsDraft: "api/bidmgmt/saveAwardAsDraft",
  submitAwardForApproval: "api/bidmgmt/submitAwardForApproval",
  processAwardApproval: "api/bidmgmt/processAwardApproval",
  acceptAward: "api/bidmgmt/acceptAward",
  rejectAward: "api/bidmgmt/rejectAward",
  requestNegotiation: "api/bidmgmt/requestNegotiation",
  downloadTechResponseDocs: "api/bidmgmt/downloadTechResponseDocs",
  downloadFinResponseDocs: "api/bidmgmt/downloadFinResponseDocs",
  createPOFromAward: "api/bidmgmt/createPOFromAward",
  cancelAward: "api/bidmgmt/cancelAward",
  getNotParticipatingList: "api/bidmgmt/getNotParticipatingList",
  getAllProdItemsByCategory: "api/productsmgmt/getALLProdItems",

  /** QuickbooksIntegration API */

  qbconnected: "api/integrationmgmt/qbconnected",
  connectToQuickbooks: "api/integrationmgmt/connectToQuickbooks",

  /** Payments API */
  createPaymentIntent: "api/paymentsmgmt/createPaymentIntent",

  /** Contracts Management **/
  getAllPublishedContracts: "api/contractmgmt/getAllPublishedContracts",
  getAllDraftContracts: "api/contractmgmt/getAllDraftContracts",
  createNewContract: "api/contractmgmt/createNewContract",
  getContractDetailsById: "api/contractmgmt/getContractDetailsById",
  deleteContract: "api/contractmgmt/deleteContract",
  addContractSupplier: "api/contractmgmt/addContractSupplier",
  deleteContractSupplier: "api/contractmgmt/deleteContractSupplier",
  saveContractTeam: "api/contractmgmt/saveContractTeam",
  deleteContractTeam: "api/contractmgmt/deleteContractTeam",
  publishContract: "api/contractmgmt/publishContract",
  getOpenContractsBySupplier: "api/contractmgmt/getOpenContractsBySupplier",
  getSignedContractsBySupplier: "api/contractmgmt/getSignedContractsBySupplier",
  saveContractDoc: "api/contractmgmt/saveContractDoc",
  importContractDoc: "api/contractmgmt/importContractDoc",
  supplierSubmitNegotiation: "api/contractmgmt/supplierSubmitNegotiation",
  buyerSubmitNegotiation: "api/contractmgmt/buyerSubmitNegotiation",
  acceptContractBySupplier: "api/contractmgmt/acceptContractBySupplier",
  submitForApproval: "api/contractmgmt/submitForApproval",
  processContractApprovalStep: "api/contractmgmt/processContractApprovalStep",
  submitForReview: "api/contractmgmt/submitForReview",
  acceptContractReview: "api/contractmgmt/acceptContractReview",
  rejectContractReview: "api/contractmgmt/rejectContractReview",
  getAllContractSections: "api/contractmgmt/getAllContractSections",
  processSections: "api/contractmgmt/processSections",
  delSections: "api/contractmgmt/delSections",
  getAllContractTerms: "api/contractmgmt/getAllContractTerms",
  processContractsTerms: "api/contractmgmt/processContractsTerms",
  deleteTerms: "api/contractmgmt/deleteTerms",
  publishTerm: "api/contractmgmt/publishTerm",
  processContractSow: "api/contractmgmt/processContractSow",
  delSowLines: "api/contractmgmt/delSowLines",
  uploadContractAttachments: "api/contractmgmt/uploadContractAttachments",
  delConAttachs: "api/contractmgmt/delConAttachs",
  getSectionsUnused: "api/contractmgmt/getSectionsUnused",
  getSubSectionUnused: "api/contractmgmt/getSubSections",
  getPublishedTerms: "api/contractmgmt/getPublishedTerms",
  uploadConAttachments: "api/bidmgmt/uploadContractAttachments",
  buildSections: "api/contractmgmt/buildSections",
  buildSubSections: "api/contractmgmt/buildSubSections",
  buildTerms: "api/contractmgmt/buildTerms",
  genContractPreview: "api/contractmgmt/genContractPreview",
  delContractTerms: "api/contractmgmt/delContractTerms",
  saveAsTemplate: "api/contractmgmt/saveAsTemplate",
  buildDeliverySchedule: "api/contractmgmt/buildDeliverySchedule",
  deleteDeliverySchedule: "api/contractmgmt/deleteDeliverySchedule",
  getALLContractTemplates: "api/contractmgmt/getALLContractTemplates",
  buildPaymentSchedule: "api/contractmgmt/buildPaymentSchedule",
  delPaymentSchedule: "api/contractmgmt/delPaymentSchedule",
  buildInspectionSchedule: "api/contractmgmt/buildInspectionSchedule",
  deleteInspectionSchedule: "api/contractmgmt/deleteInspectionSchedule",
  getContractDetailsByTempleteId:
    "api/contractmgmt/getContractDetailsByTempleteId",
  editContractTermDetails: "api/contractmgmt/editContractTermDetails",
  getTermsHistory: "api/contractmgmt/getTermsHistory",
  reviseTermDetails: "api/contractmgmt/reviseTermDetails",
  createRevision: "api/contractmgmt/createRevision",
  getAllContractTermsforHst: "api/contractmgmt/getAllContractTermsforHst",
  buyerSowRevComments: "api/contractmgmt/buyerSowRevComments",
  buyerDelSchRevComments: "api/contractmgmt/buyerDelSchRevComments",
  buyerInsSchRevComments: "api/contractmgmt/buyerInsSchRevComments",
  suppSowRevComments: "api/contractmgmt/suppSowRevComments",
  suppSowComments: "api/contractmgmt/suppSowComments",
  suppDelSchRevComments: "api/contractmgmt/suppDelSchRevComments",
  suppDelSchComments: "api/contractmgmt/suppDelSchComments",
  suppInsSchRevComments: "api/contractmgmt/suppInsSchRevComments",
  suppInsSchComments: "api/contractmgmt/suppInsSchComments",
  suppReviseTermDetails: "api/contractmgmt/suppReviseTermDetails",
  getALLContraactsVersions: "api/contractmgmt/getALLContractsVersions",
  acceptALLTerms: "api/contractmgmt/acceptALLTerms",
  checkTermsAcceptance: "api/contractmgmt/checkTermsAcceptance",
  checkReviewAcceptance: "api/contractmgmt/checkReviewAcceptance",
  acceptTerm: "api/contractmgmt/acceptTerm",
  checkAuthorizedPerson: "api/contractmgmt/checkAuthorizedPerson",
  suppSignedContract: "api/contractmgmt/suppSignedContract",
  activateContract: "api/contractmgmt/activateContract",
  cancelContract: "api/contractmgmt/cancelContract",
  amendContract: "api/contractmgmt/amendContract",
  renewContract: "api/contractmgmt/renewContract",
  terminateContract: "api/contractmgmt/terminateContract",

  /*prodyct-master*/
  addNewProduct: "api/productmaster/addNewProduct",
  getAllProducts: "api/productmaster/getAllProducts",
  getProductDetailsById: "api/productmaster/getProductDetailsById",
  getAllProductsByCategory: "api/productmaster/getAllProductsByCategory",
  processImportProductsXls: "api/productmaster/processImportProductsXls",

  /*auction-event-apis*/
  getAllEventTemplates: "api/auctionevents/getAllEventTemplates",
  createEventTemplate: "api/auctionevents/createEventTemplate",
  deleteTemplateRow: "api/auctionevents/deleteTemplateRows",
  getEventTemplateById: "api/auctionevents/getEventTemplateById",
  createAuctionEvent: "api/auctionevents/createAuctionEvent",
  copyAuctionEvent: "api/auctionevents/copyAuction",
  deleteBidSummary: "api/auctionevents/deleteResponse",
  // getAllTnCByModuleName: "api/admmgmt/getAllTnCByModuleName",
  createNewOrgTnC: "api/admmgmt/createNewOrgTnC",
  getAllActiveAuctions: "api/auctionevents/getAllActiveAuctions",
  getAllCompletedAuctions: "api/auctionevents/getAllCompletedAuctions",
  getAllUpcomingAuctions: "api/auctionevents/getAllUpcomingAuctions",
  getTnCByIds: "api/admmgmt/getTnCByIds",
  getAuction: "api/auctionevents/getAllActiveAuctions",
  getAuctionEventById: "api/auctionevents/getAuctionEventById",
  getAllAuctionEventsBySupplier:
    "api/auctionevents/getAllAuctionEventsBySupplier",
  getAllActiveAuctionBySupplier:
    "api/auctionevents/getAllActiveAuctionBySupplier",
  getAllCompletedAuctionBySupplier:
    "api/auctionevents/getAllCompletedAuctionBySupplier",
  getAllUpcomingAuctionBySupplier:
    "api/auctionevents/getAllUpcomingAuctionBySupplier",
  getAuctionEventDetailsById: "api/auctionevents/getAuctionEventDetailsById",
  saveSupplierResponse: "api/auctionevents/sendAuctionSuppRespEvents",
  getAuctionEventDetailsBySupp:
    "api/auctionevents/getAuctionEventDetailsBySupp",
  updateSupplierResponse: "api/auctionevents/updateAuctionSuppRespEvents",
  withdrawAuction: "api/auctionevents/withdrawAuction",
  broadCastMessage: "api/auctionevents/broadCastMessage",
  extendTimeRemain: "api/auctionevents/extendTimeRemain",
  bidBroadCastMessage: "api/bidmgmt/BidbroadCastMessage",
  updateSupplierAuctionSeenStatus:
    "api/auctionevents/updateSupplierAuctionSeenStatus",
  getBroadCastMessage: "api/auctionevents/getBroadCastMessage",
  getBidBroadCastMessage: "api/bidmgmt/getBidBroadCastMessage",
  getOrderActivity: "api/auctionevents/getOrderActivity",
  updateMinBidDiff: "api/auctionevents/updateMinBidDiff",
  addAuctionSuppliers: "api/auctionevents/addAuctionSuppliers",
  getBidSummary: "api/auctionevents/getBidSummary",
  getBidSummaryPartial: "api/auctionevents/getBidSummaryPartial",
  getBidSummaryHistBySupplier: "api/auctionevents/getBidSummaryHistBySupplier",
  getBidDeleteRequestBySupp: "api/auctionevents/getBidDeleteRequestBySupp",
  awardAuction: "api/auctionevents/awardAuction",
  updateAuctionAPI: "api/auctionevents/subscribeAu",
  submitAwardForApprovalAuction: "api/auctionevents/submitAwardForApproval",
  processAwardApprovalLot: "api/auctionevents/processAwardApproval",
  cancelAuctionAward: "api/auctionevents/cancelAward",
  getAwardDetailsAuction: "api/auctionevents/getAwardDetails",
  submitAwardApprovalPartial: "api/auctionevents/submitAwardApprovalPartial",
  processAwardApprovalPartial: "api/auctionevents/processAwardApprovalPartial",
  createPOFromAuction: "api/auctionevents/createPOFromAward",
  generateReport: "api/auctionevents/generateReport",
  generateExcelReport: "api/auctionevents/generateExcelReport",
  getAllDraftAuctions: "api/auctionevents/getDraftAuctions",
  /*mobile-interface-apis*/
  mobGetAllAuctionEvents: "mobile/api/service/getAllAuctionEvents",
  mobGetAllActiveAuctions: "mobile/api/service/getAllActiveAuctions",
  mobGetAuctionsById: "mobile/api/service/getAuctionEventById",
};
