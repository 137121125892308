import React, { useEffect, useRef, useState } from "react";
import emptyIcon from "../../assets/images/illustrations/empty.png";
import { env, env_url } from "../../environments/env";
import { getCsrfToken } from "../../functions/common";
import Custommodal from "../custommodal";

const TermsConditions = (props) => {
  const { type } = props;

  const termsConditionsModal = useRef(null);
  const [modalLoader, setModalLoader] = useState(false);
  const [termsConditionDetails, setTermsConditionDetails] = useState("");

  useEffect(() => {
    if (type) {
      setModalLoader(true);
      let token = sessionStorage.getItem("token");
      const url = env.baseUrl + env_url.getTermsConditionDocument + "/" + type;
      const headerProps = {
        Accept: "application/pdf",
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": "max-age=65540 ; includeSubDomains",
        "Content-Security-Policy": `default-src 'self'`,
        authorization: "Bearer " + token,
        "X-CSRF-TOKEN": getCsrfToken(),
        "X-XSRF-TOKEN": getCsrfToken(),
        "X-Content-Type-Options": "nosniff",
        "X-XSS-Protection": "1; mode=block",
        "X-Frame-Options": "deny",
      };
      fetch(url, {
        method: "GET",
        headers: headerProps,
        responseType: "arraybuffer",
      })
        .then((response) => response?.blob())
        .then((blob) => {
          if (blob?.size > 0) {
            const url = URL.createObjectURL(
              new Blob([blob], { type: "application/pdf" })
            );
            setTermsConditionDetails(url);
            setModalLoader(false);
          } else {
            setTermsConditionDetails("");
            setModalLoader(false);
          }
        })
        .catch((_err) => {
          setTermsConditionDetails("");
          setModalLoader(false);
        });
    }
    return () => {
      setTermsConditionDetails("");
      setModalLoader(false);
    };
  }, [type]);

  const toggleTermsConditions = (e) => {
    e.preventDefault();
    termsConditionsModal.current.toggleModal();
  };

  return (
    <>
      <span
        className={props.class ? props.class : "link"}
        onClick={(e) => toggleTermsConditions(e)}
      >
        Terms &amp; Conditions
      </span>

      <Custommodal
        ref={termsConditionsModal}
        className="confirmation size-lg"
        heading="Terms and Conditions"
        centered
        showClose
        content={
          <div className="confirm-dialog">
            {modalLoader ? (
              <div className="col-12 text-center">
                <div className="loader" />
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-12">
                  <div>
                    {termsConditionDetails ? (
                      <>
                        <object
                          aria-label="terms-conditions-document"
                          data={termsConditionDetails}
                          width="100%"
                          height="500"
                        />
                      </>
                    ) : (
                      <div className="col-12 text-center">
                        <img
                          src={emptyIcon}
                          alt="folder-empty"
                          height="88"
                          width="88"
                        />
                        <h4 className="text-dull">No Document found</h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default React.memo(TermsConditions);

